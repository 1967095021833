import styles from './Badges.module.css';
import img1 from './assets/g2_1.png';
import img2 from './assets/g2_2.svg';
import img3 from './assets/g2_3.svg';
import img4 from './assets/g2_4.svg';
import img5 from './assets/g2_5.svg';
import img6 from './assets/g2_6.svg';
import img7 from './assets/g2_7.svg';

function Brands() {
    return (
        <div className={styles.badges}>
            <img
                src={img1.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img2.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img3.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img4.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img5.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img6.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
            <img
                src={img7.src}
                alt=""
                width={'88.5px'}
                height={'114px'}
                loading="lazy"
            />
        </div>
    );
}

export default Brands;
