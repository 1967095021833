import React from 'react';

interface FormattedPriceProps {
    currency?: string | null;
    locale?: string;
    value: number | null;
    period?: string;
    fractionDigits?: number;
    numberFormatOptions?: Intl.NumberFormatOptions;
    styles?: {
        container?: string;
        currency?: string;
        value?: string;
        period?: string;
    };
}

const valueParts = ['integer', 'group', 'decimal', 'fraction'];

function FormattedPrice({ currency, locale = 'en', value, styles, period, fractionDigits = 2, numberFormatOptions, ...otherProps }: FormattedPriceProps): JSX.Element {
    currency = currency == null ? 'USD' : currency;
    value = value == null ? 0 : value;

    const formatter = Intl.NumberFormat(locale, {
        style: 'currency',
        useGrouping: false,
        currency: currency,
        notation: 'standard',
        currencyDisplay: ['MXN', 'SGD', 'CHF', 'CLP', 'COP'].includes(currency) ? 'code' : 'narrowSymbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: value % 1 === 0 ? 0 : fractionDigits,
        ...numberFormatOptions,
    });

    let plnValue: string | null = null;
    let literalValue: string | null = null;

    const parts = formatter.formatToParts(value);

    const mergedParts = parts.reduce((mergedParts: Array<Intl.NumberFormatPart | Array<Intl.NumberFormatPart>>, part) => {
        const newParts = [...mergedParts];
        if (valueParts.includes(part.type)) {
            if (newParts.length > 0 && Array.isArray(newParts[newParts.length - 1])) {
                const lastPart = newParts[newParts.length - 1] as Array<Intl.NumberFormatPart>;
                lastPart.push(part);
            } else {
                newParts.push([part]);
            }
            return newParts;
        }
        newParts.push(part);
        return newParts;
    }, []);

    return (
        <span
            className={styles?.container}
            {...otherProps}
            data-currency={currency}
        >
            {mergedParts.map((part, i) => {
                if (Array.isArray(part)) {
                    return (
                        <span
                            className={styles?.value}
                            key={i}
                        >
                            {part.map((p) => p.value).join('')}
                        </span>
                    );
                }
                if (part.type === 'currency' && currency === 'PLN') {
                    plnValue = part.value;
                    return null;
                }
                if (part.type === 'literal') {
                    literalValue = part.value;
                    return null;
                }
                if (part.type === 'currency') {
                    return (
                        <span
                            className={styles?.currency}
                            key={i}
                        >
                            {part.value}
                        </span>
                    );
                }
            })}
            {plnValue && <span className={styles?.currency}>{plnValue}</span>}
            {period && <span className={styles?.period}>{period}</span>}
        </span>
    );
}

export default FormattedPrice;
