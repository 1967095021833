export type EventState = {
    state: 'viewed' | 'buttonClicked' | 'linkClicked';
    placement:
        | 'homepage-challenge-section'
        | 'selling-knowledge-challenge-section'
        | 'pricing-challenge-section'
        | 'homepage-hero'
        | 'homepage-hero-creator'
        | 'homepage-hero-inboxer'
        | 'homepage-hero-automator'
        | 'homepage-hero-cartmaster'
        | 'homepage-hero-maximizer';
};

export class BFCM2024GtmEvent {
    private static readonly EVENT_MAP: Record<
        EventState['placement'],
        {
            placement: string;
            events: Partial<Record<EventState['state'], string>>;
        }
    > = {
        'homepage-challenge-section': {
            placement: 'challenge section',
            events: {
                viewed: 'bfcm24_cmp_challenge_viewed',
                buttonClicked: 'bfcm24_cmp_challenge_clicked',
            },
        },
        'selling-knowledge-challenge-section': {
            placement: 'selling knowledge',
            events: {
                viewed: 'bfcm24_challenge_viewed',
                buttonClicked: 'bfcm24_challenge_button_clicked',
                linkClicked: 'bfcm24_challenge_link_clicked',
            },
        },
        'pricing-challenge-section': {
            placement: 'pricing',
            events: {
                viewed: 'bfcm24_challenge_viewed',
                buttonClicked: 'bfcm24_challenge_button_clicked',
                linkClicked: 'bfcm24_challenge_link_clicked',
            },
        },
        'homepage-hero': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_promo_banner_viewed',
                linkClicked: 'bfcm24_promo_banner_clicked',
            },
        },
        'homepage-hero-creator': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_CMP_persona_viewed',
                linkClicked: 'bfcm24_CMP_persona_clicked',
            },
        },
        'homepage-hero-inboxer': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_EM_persona_viewed',
                linkClicked: 'bfcm24_EM_persona_clicked',
            },
        },
        'homepage-hero-automator': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_MA_persona_viewed',
                linkClicked: 'bfcm24_MA_persona_clicked',
            },
        },
        'homepage-hero-cartmaster': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_EC_persona_viewed',
                linkClicked: 'bfcm24_EC_persona_clicked',
            },
        },
        'homepage-hero-maximizer': {
            placement: 'Homepage header',
            events: {
                viewed: 'bfcm24_MAX_persona_viewed',
                linkClicked: 'bfcm24_MAX_persona_clicked',
            },
        },
    };

    constructor(private readonly payload: EventState) {
        this.push();
    }

    private push(): void {
        window.dataLayer = window.dataLayer || [];

        const { placement, events } = BFCM2024GtmEvent.EVENT_MAP[this.payload.placement];

        window.dataLayer.push({
            event: events[this.payload.state],
            placement,
        });
    }
}
