import React, { useState } from 'react';

import type { Plan } from '@components/pages/homepage/ExitPopup';

import clsx from 'clsx';

import styles from './Checkbox.module.css';

interface CheckboxProps {
    label: string;
    value: Plan;
    name: string;
    onClick: (value: Plan, checked: boolean) => void;
}

export default function Checkbox({ value, label, onClick, name }: CheckboxProps) {
    const [isChecked, setIsChecked] = useState(false);
    const handleOnClick = (value: Plan) => {
        if (isChecked) {
            setIsChecked(false);
            onClick(value, false);
        } else {
            setIsChecked(true);
            onClick(value, true);
        }
    };
    return (
        <label className={clsx(styles.label, isChecked && styles.checked)}>
            <input
                autoComplete="off"
                onClick={() => handleOnClick(value)}
                type="checkbox"
                name={name}
                value={value}
            />
            <span className={styles.check}></span>
            {label}
        </label>
    );
}
