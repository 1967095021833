import { Button } from '@components/ui/design2023/Button';
import React from 'react';
import Trans from '@components/Trans';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';
import styles from './MaxBanner.module.css';
import { useRouter } from 'next/router';
import useTranslation from '@hooks/useTranslation';

const MaxBanner = ({ sticky = true }: { sticky?: boolean }) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    return (
        <aside className={[styles.banner, sticky ? styles.sticky : null].filter(Boolean).join(' ')}>
            <p>
                <Trans
                    i18nKey="maxBanner"
                    components={{
                        link: <a href={laravelRouteMaxBookADemo(locale)} />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </p>
            <Button
                label={t('maxBannerButton')}
                color={'orange'}
                href={laravelRouteMaxBookADemo(locale)}
            />
        </aside>
    );
};

export default MaxBanner;
