import React, { createContext, useEffect, useRef, useState } from 'react';

import styles from './ScrollableTabs.module.css';

type Tab = {
    title: string;
    content: JSX.Element;
};
interface ScrollableTabsProps {
    onActiveTabChange?: Function;
    tabs: Array<Tab>;
}

const TabContext = createContext({
    active: false,
});

const ScrollableTabs = ({ tabs, onActiveTabChange }: ScrollableTabsProps) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tabsVisibilityStates, setTabsVisibilityStates] = useState<Array<IntersectionObserverEntry | null>>(tabs.map((_tab) => null));
    const contentWrapperRefs = useRef<(HTMLDivElement | null)[]>([]);
    const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

    const handleTabChange = (e: React.MouseEvent<HTMLElement>, index: number) => {
        e.preventDefault();
        setActiveTab(index);
        if (contentWrapperRefs !== null) {
            contentWrapperRefs.current?.[index]?.scrollIntoView({ block: 'center', inline: 'center' });
        }
    };

    useEffect(() => {
        let options = {
            rootMargin: '-49.5%',
            threshold: [...Array(101).keys()].map((k) => k / 100),
        };

        let observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                const entryIndex = contentRefs.current.indexOf(entry.target as HTMLDivElement);
                setTabsVisibilityStates((oldStates) => {
                    const newStates = [...oldStates];
                    newStates[entryIndex] = entry;
                    return newStates;
                });
            });
        }, options);

        contentRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            observer.disconnect();
        };
    }, [contentRefs]);

    useEffect(() => {
        const intersectionRatios = tabsVisibilityStates.map((vs) => vs?.intersectionRatio);

        if (intersectionRatios.filter(Boolean).length === 0) {
            return;
        }
        for (let i = intersectionRatios.length - 1; i >= 0; i--) {
            if (intersectionRatios[i] !== 0) {
                setActiveTab(i);
                return;
            }
        }
    }, [tabsVisibilityStates]);

    useEffect(() => {
        if (onActiveTabChange) {
            onActiveTabChange(activeTab);
        }
    }, [activeTab, onActiveTabChange]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {tabs.map((tab, index) => (
                    <a
                        href="#"
                        className={[styles.tab, index === activeTab ? styles.activeTab : false].filter(Boolean).join(' ')}
                        onClick={(e) => handleTabChange(e, index)}
                        key={index}
                    >
                        {tab.title}
                    </a>
                ))}
            </div>
            <div className={styles.content}>
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={styles.tabContentWrapper}
                        ref={(element) => (contentWrapperRefs.current[index] = element)}
                    >
                        <div
                            className={[styles.tabContent, index === activeTab ? styles.tabContentActive : false].filter(Boolean).join(' ')}
                            ref={(element) => (contentRefs.current[index] = element)}
                        >
                            <h3 className={styles.mobileHeader}>{tab.title}</h3>

                            <TabContext.Provider value={{ active: index === activeTab }}>{tab.content}</TabContext.Provider>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
ScrollableTabs.TabContext = TabContext;
export default ScrollableTabs;
