import React from 'react';

export const useIntersectionObserverRef = <T extends HTMLElement>(callback: () => void, options: IntersectionObserverInit = { threshold: 0.2 }) => {
    const elementRef = React.useRef<T>(null);
    const wasTriggered = React.useRef(false);

    React.useEffect(() => {
        const element = elementRef.current;
        if (!element) {
            return;
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !wasTriggered.current) {
                    callback();
                    wasTriggered.current = true;
                    observer.disconnect();
                }
            });
        }, options);

        observer.observe(element);

        return () => {
            observer.disconnect();
        };
    }, [callback, options]);

    return elementRef;
};
