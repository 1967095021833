import { useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import Trans from '@components/Trans';
import Brands from '@components/global/Brands';
import Container from '@components/global/Container';
import G2Reviews from '@components/global/G2Reviews';
import Seo from '@components/global/Seo';
import WhyGetresponse from '@components/global/WhyGetresponse';
import CoursesSection from '@components/global/design2023/CoursesSection';
import Layout from '@components/layouts/Layout';
import BFCM2024ChallengeBanner from '@components/pages/homepage/BFCM2024ChallengeBanner';
import Badges from '@components/pages/homepage/Badges';
import CoreOfferBanner from '@components/pages/homepage/CoreOfferBanner';
import EmailMarketingAndAutomationBanner from '@components/pages/homepage/EmailMarketingAndAutomationBanner';
import ExitPopup from '@components/pages/homepage/ExitPopup';
import Goals from '@components/pages/homepage/Goals';
import Integrations from '@components/pages/homepage/Integrations';
import JsonLd from '@components/pages/homepage/JsonLd';
import MaxBanner from '@components/pages/homepage/MaxBanner';
import Pricing from '@components/pages/homepage/Pricing';
import { type PricingBannerProps } from '@components/pages/homepage/PricingBanner';

import { CustomersSupportedLocales, getCustomersPosts } from '@helpers/customers/helpers';
import getGlobalProps from '@helpers/getGlobalProps';
import getLocale from '@helpers/getLocale';
import getPageLocales from '@helpers/getPageLocales';
import { type PropsWithSeoData } from '@helpers/seo';
import { addQueryParamsToSerachParams } from '@helpers/utils';

import socialShareDefault from '@public/share.jpg';
import socialShareDe from '@public/share_de.png';
import socialShareEs from '@public/share_es.png';
import socialShareFr from '@public/share_fr.png';
import socialShareIt from '@public/share_it.png';
import socialSharePl from '@public/share_pl.png';
import socialSharePt from '@public/share_pt.png';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteStartFree } from '@static_components/laravelLinks';
import clsx from 'clsx';
import type { GetStaticProps } from 'next';

import styles from '@styles/pages/homepage/Main.module.css';

const PromoBlackFriday2024 = dynamic(() => import('@components/pages/homepage/PromoBlackFriday2024/Hero'), {
    ssr: true,
});

const Hero = dynamic(() => import('@components/pages/homepage/Hero'), {
    ssr: true,
});
const PricingBannerPromo = dynamic(() => import('@components/pages/homepage/PricingBannerPromo'), {
    ssr: true,
});
const PricingBanner = dynamic(() => import('@components/pages/homepage/PricingBanner'), {
    ssr: true,
});

const ContainerWrap = ({ children }: React.PropsWithChildren) => {
    return <div className={styles.containerWrap}>{children}</div>;
};

interface PricingBannerWrapperProps extends PricingBannerProps {
    hero: 1 | 2 | 3 | 4;
}

const PricingBannerWrapper = (props: PricingBannerWrapperProps) => {
    return props.isPromoActive ? (
        <PricingBannerPromo
            hero={props.hero}
            currency={props.currency}
            price={props.price}
            promoPrice={props.promoPrice}
        />
    ) : (
        <PricingBanner
            currency={props.currency}
            isPromoActive={props.isPromoActive}
            price={props.price}
            promoPrice={props.promoPrice}
        />
    );
};

export interface HomepageProps {
    isPromoActive?: boolean;
    promoCounter?: number;
    customersPosts: Array<{
        title: string;
        url: string;
        image: {
            url: string;
            alt?: string;
        };
        description: string;
    }>;
    isTestVersion?: boolean;
    testHeroComponent?: JSX.Element;
}

export default function Homepage({ isPromoActive = false, promoCounter = 0, customersPosts, seo, isTestVersion = false, testHeroComponent }: PropsWithSeoData<HomepageProps>) {
    const { geo } = useAppContext();

    if (!geo.pricing) {
        throw new Error('Missing pricing data');
    }
    const { currency, plans } = geo.pricing;

    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();

    const getOgImage = (locale: string | undefined): string => {
        switch (locale) {
            case 'pl':
                return socialSharePl.src;
            case 'de':
                return socialShareDe.src;
            case 'fr':
                return socialShareFr.src;
            case 'es':
                return socialShareEs.src;
            case 'it':
                return socialShareIt.src;
            case 'pt':
                return socialSharePt.src;
            default:
                return socialShareDefault.src;
        }
    };

    const [goalsActiveTab, setGoalsActiveTab] = useState(0);

    let pricingBanner;

    switch (goalsActiveTab) {
        case 0:
            pricingBanner = plans[1].plans && (
                <PricingBannerWrapper
                    hero={1}
                    currency={currency}
                    isPromoActive={isPromoActive}
                    price={Math.floor(plans[1].plans[0].perPeriodWithoutDiscountPrice)}
                    promoPrice={isPromoActive ? Math.floor(plans[1].plans[0].lowestDiscountPrice) : null}
                />
            );
            break;
        case 1:
            pricingBanner = plans[1].plans && (
                <PricingBannerWrapper
                    hero={2}
                    currency={currency}
                    isPromoActive={isPromoActive}
                    price={Math.floor(plans[1].plans[1].perPeriodWithoutDiscountPrice)}
                    promoPrice={isPromoActive ? Math.floor(plans[1].plans[1].lowestDiscountPrice) : null}
                />
            );
            break;
        case 2:
            pricingBanner = plans[1].plans && (
                <PricingBannerWrapper
                    hero={3}
                    currency={currency}
                    isPromoActive={isPromoActive}
                    price={Math.floor(plans[1].plans[2].perPeriodWithoutDiscountPrice)}
                    promoPrice={isPromoActive ? Math.floor(plans[1].plans[2].lowestDiscountPrice) : null}
                />
            );
            break;
        case 3:
            pricingBanner = isPromoActive ? <PricingBannerPromo hero={4} /> : <MaxBanner />;
            break;
    }

    return (
        <>
            <Seo
                title={t('pages/homepage/2024/v1/index:seo.title')}
                description={t('pages/homepage/2024/v1/index:seo.description')}
                ogImage={getOgImage(locale)}
                noindex={!seo.robots.index}
                nofollow={!seo.robots.follow}
                canonical={seo.canonical}
                languageAlternates={isTestVersion ? [] : undefined}
            />
            <JsonLd />
            {isPromoActive !== true ? <CoreOfferBanner /> : null}
            <main className={styles.main}>
                {testHeroComponent ? testHeroComponent : null}
                {isPromoActive === true ? (
                    !testHeroComponent ? (
                        <PromoBlackFriday2024 counter={promoCounter} />
                    ) : null
                ) : !testHeroComponent ? (
                    <>
                        <Hero container={<Container maxWidth={'1330px'} />} />
                        {['en'].includes(locale ?? '') && <G2Reviews customStyles={{ g2reviews: styles.g2reviews }} />}
                    </>
                ) : null}
                <ContainerWrap>
                    <Goals onActiveTabChange={setGoalsActiveTab} />
                    {pricingBanner}
                </ContainerWrap>
                {locale !== 'ru' && <CoursesSection maxWidth={'1140px'} />}
                {locale === 'en' && isPromoActive ? <BFCM2024ChallengeBanner /> : null}
                <Container maxWidth={'1140px'}>
                    <WhyGetresponse
                        customers={customersPosts}
                        header={
                            <Trans
                                i18nKey="whyGetresponseHeader"
                                components={{
                                    u: <span />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        number1={t('whyGetresponseNumber1')}
                        text1={t('whyGetresponseText1')}
                        number2={t('whyGetresponseNumber2')}
                        text2={
                            <Trans
                                i18nKey="whyGetresponseText2"
                                components={{
                                    b: <strong />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        }
                        number3={t('whyGetresponseNumber3_v_b')}
                        text3={t('whyGetresponseText3')}
                        number4={t('whyGetresponseNumber4')}
                        text4={t('whyGetresponseText4')}
                    />
                    <Badges />
                </Container>
                <Integrations container={<Container maxWidth={'1170px'} />} />
                <Container maxWidth={'1170px'}>
                    <Pricing isPromoActive={isPromoActive} />
                </Container>
                <Container maxWidth={'1140px'}>
                    <Brands className={styles.brands} />
                </Container>
                <EmailMarketingAndAutomationBanner container={<Container maxWidth={'1140px'} />} />
                {isPromoActive ? <ExitPopup /> : null}
            </main>
        </>
    );
}

export const getStaticProps: GetStaticProps = async (context) => {
    const globalProps = await getGlobalProps(context, '/');
    let locale = getLocale(context);
    // Disable seasonal promo bar on homepage
    const isSeasonalPromoActive = !!globalProps.isSeasonalPromoActive;
    const accountsLeft = globalProps.seasonalPromoCounterValue ? globalProps.seasonalPromoCounterValue : null;
    globalProps.isSeasonalPromoActive = false;
    const customersPosts = await getCustomersPosts(locale as CustomersSupportedLocales);

    const startFreeUrl = addQueryParamsToSerachParams({ version: 'centered' }, laravelRouteStartFree(locale));

    return {
        props: {
            ...globalProps,
            isPromoActive: isSeasonalPromoActive,
            promoCounter: accountsLeft ?? null,
            customersPosts,
            headerProps: {
                startFreeUrl,
                additionalHeaderAttribute: 'has-new-layout',
                darkMode: isSeasonalPromoActive,
            },
        },
        revalidate: 1 * 60,
    };
};

export const getStaticPaths = async () => {
    const locales = getPageLocales('/');
    return {
        paths: locales.map((locale: any) => ({
            params: {
                locale,
            },
        })),
        fallback: false,
    };
};

Homepage.getLayout = function getLayout(page: JSX.Element) {
    // const { locale } = useRouter();
    // const startFreeUrl = addQueryParamsToSerachParams({ version: 'centered' }, laravelRouteStartFree(locale));
    return (
        <Layout
            // headerProps={{ startFreeUrl, additionalHeaderAttribute: 'has-new-layout' }}
            // Force pathname to make all "versions" have same language alternates
            footerProps={{ langSwitcherPathname: '/' }}
        />
    );
};
