import React from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import Trans from '@components/Trans';
import CustomLink from '@components/global/CustomLink';
import FormattedPrice from '@components/global/FormattedPrice';
import DeskoptBr from '@components/ui/DeskoptBr';
import { Button } from '@components/ui/design2023/Button';

import useTranslation from '@hooks/useTranslation';
import { laravelRouteMaxBookADemo } from '@static_components/laravelLinks';
import clsx from 'clsx';

import { AutomatorMask, CartMasterMask, CreatorMask, InboxerMask, MaximizerMask } from './BlackFriday24Mask';
import styles from './Pricing.module.css';
import { ReactComponent as SVGCreatorsIcon } from './assets/creators-icon.svg';
import { ReactComponent as SVGMarketersIcon } from './assets/marketers-icon.svg';

interface PricingProps {
    isPromoActive?: boolean;
}

const Pricing = ({ isPromoActive = false }: PricingProps) => {
    const { geo } = useAppContext();
    if (!geo.pricing || !geo.pricingCreator) {
        throw new Error('Missing pricing data');
    }
    const { currency, plans } = geo.pricing;
    const { currencyCreator, plansCreator } = geo.pricingCreator;

    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    return (
        <section className={styles.pricing}>
            <h2 className={styles.header}>
                <Trans
                    i18nKey="pricingHeader_v_b"
                    components={{
                        u: <span />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </h2>
            <div className={clsx(styles.plans, isPromoActive && styles.blackFriday24)}>
                <div className={styles.planHead}>
                    <SVGMarketersIcon />
                    <span>{t('pricingMarketers')}</span>
                    <SVGMarketersIcon />
                </div>
                <div className={clsx(styles.plan, styles.planMarketers)}>
                    {isPromoActive ? (
                        <>
                            <div className={styles.masksList}>
                                <InboxerMask />
                                <AutomatorMask />
                                <CartMasterMask />
                            </div>
                            <h3 className={clsx(styles.planHeader, styles.planHeaderPromo)}>
                                <Trans
                                    i18nKey="pricingPlanHeaderMarketersPromo"
                                    components={{
                                        mark: <mark className={styles.mark} />,
                                        br: <DeskoptBr />,
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            </h3>
                        </>
                    ) : (
                        <h3 className={styles.planHeader}>
                            <Trans
                                i18nKey="pricingPlanHeader1_v_b"
                                components={{
                                    br: <DeskoptBr />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        </h3>
                    )}
                    {isPromoActive ? (
                        <p className={clsx(styles.piceBlock, styles.piceBlockPromo)}>
                            <Trans
                                i18nKey="pricingPlanBannerPromo"
                                components={{
                                    price: plans[1].plans && (
                                        <>
                                            {/* <FormattedPrice
                                                value={Math.floor(plans[1].plans[0].perPeriodWithoutDiscountPrice)}
                                                locale={locale?.replace('vn', 'vi')}
                                                currency={currency}
                                                styles={{
                                                    container: styles.oldPrice,
                                                    currency: styles.priceCurrency,
                                                    value: styles.priceValue,
                                                }}
                                            /> */}
                                            <FormattedPrice
                                                value={Math.floor(plans[1].plans[0].lowestDiscountPrice)}
                                                locale={locale?.replace('vn', 'vi')}
                                                currency={currency}
                                                period={t('pricingPlanBannerPlan1')}
                                                styles={{
                                                    container: styles.price,
                                                    currency: styles.priceCurrency,
                                                    value: styles.priceValue,
                                                    period: styles.pricePeriod,
                                                }}
                                            />
                                        </>
                                    ),
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        </p>
                    ) : (
                        <p className={styles.piceBlock}>
                            {plans[1]?.plans && (
                                <Trans
                                    i18nKey="pricingPlanBanner1"
                                    components={{
                                        price: (
                                            <FormattedPrice
                                                value={Math.floor(plans[1].plans[0].perPeriodWithoutDiscountPrice)}
                                                locale={locale?.replace('vn', 'vi')}
                                                currency={currency}
                                                period={t('pricingPlanBannerPlan1')}
                                                styles={{
                                                    container: styles.price,
                                                    currency: styles.priceCurrency,
                                                    value: styles.priceValue,
                                                    period: styles.pricePeriod,
                                                }}
                                            />
                                        ),
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            )}
                        </p>
                    )}
                    <p className={styles.includeHeader}>{t('pricingPlanIncludeHeader1')}</p>
                    <div className={styles.features}>
                        <ul>
                            <Trans
                                i18nKey="pricingPlanFeatures1"
                                components={{
                                    li: <li />,
                                    strong: <strong />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        </ul>
                        <span className={styles.lastElement}>{t('pricingPlanFeaturesMore')}</span>
                    </div>
                    <div className={styles.banner}>
                        <Button
                            label={t('pricingPlanBannerButton1')}
                            size={'medium'}
                            color={'yellow'}
                            customLink={<CustomLink href={{ pathname: 'pricing' }} />}
                        />
                    </div>
                </div>
                <div className={styles.planHead}>
                    <SVGMarketersIcon />
                    <span>{t('pricingMarketers')}</span>
                    <SVGMarketersIcon />
                </div>
                <div className={clsx(styles.plan, styles.planEnterprise)}>
                    {isPromoActive ? (
                        <>
                            <div className={styles.masksList}>
                                <MaximizerMask />
                            </div>
                            <h3 className={clsx(styles.planHeader, styles.planHeaderPromo)}>
                                <Trans
                                    i18nKey="pricingPlanHeaderEnterprisePromo"
                                    components={{
                                        mark: <mark className={styles.mark} />,
                                        br: <DeskoptBr />,
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            </h3>
                        </>
                    ) : (
                        <h3 className={styles.planHeader}>
                            <Trans
                                i18nKey="pricingPlanHeader2_v_b"
                                components={{
                                    br: <DeskoptBr />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        </h3>
                    )}
                    <p className={styles.piceBlock}>{t('pricingPlanBanner2')}</p>
                    <p className={styles.includeHeader}>{t('pricingPlanIncludeHeader2')}</p>
                    <div className={styles.features}>
                        <ul>
                            <Trans
                                i18nKey="pricingPlanFeatures2"
                                components={{
                                    li: <li />,
                                    strong: <strong />,
                                }}
                                ns="pages/homepage/2024/v1/index"
                            />
                        </ul>
                        <span className={styles.lastElement}>{t('pricingPlanFeaturesMore')}</span>
                    </div>
                    <div className={styles.banner}>
                        <Button
                            label={t('pricingPlanBannerButton2')}
                            size={'medium'}
                            color={'orange'}
                            href={laravelRouteMaxBookADemo(locale)}
                        />
                    </div>
                </div>

                <>
                    {!isPromoActive && (
                        <div className={clsx(styles.planCreatorsHead, styles.planHead)}>
                            <SVGCreatorsIcon />
                            <span>{t('pricingCreators')}</span>
                            <SVGCreatorsIcon />
                        </div>
                    )}
                    <div className={clsx(styles.plan, styles.planCreators)}>
                        {isPromoActive ? (
                            <>
                                <div className={styles.masksList}>
                                    <CreatorMask />
                                </div>
                                <h3 className={clsx(styles.planHeader, styles.planHeaderPromo)}>
                                    <Trans
                                        i18nKey="pricingPlanHeaderCreatorsPromo"
                                        components={{
                                            mark: <mark className={styles.mark} />,
                                            br: <DeskoptBr />,
                                        }}
                                        ns="pages/homepage/2024/v1/index"
                                    />
                                </h3>
                            </>
                        ) : (
                            <h3 className={styles.planHeader}>
                                <Trans
                                    i18nKey="pricingPlanHeader3_v_b"
                                    components={{
                                        br: <DeskoptBr />,
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            </h3>
                        )}
                        {isPromoActive ? (
                            <p className={clsx(styles.piceBlock, styles.piceBlockPromo)}>
                                <Trans
                                    i18nKey="pricingPlanBannerPromo"
                                    components={{
                                        price: plansCreator[1]?.plans && (
                                            <>
                                                {/* <FormattedPrice
                                                    value={Math.floor(plansCreator[1].plans[0].perPeriodWithoutDiscountPrice)}
                                                    locale={locale?.replace('vn', 'vi')}
                                                    currency={currencyCreator}
                                                    styles={{
                                                        container: styles.oldPrice,
                                                        currency: styles.priceCurrency,
                                                        value: styles.priceValue,
                                                    }}
                                                /> */}
                                                <FormattedPrice
                                                    value={Math.floor(plansCreator[1].plans[0].lowestDiscountPrice)}
                                                    locale={locale?.replace('vn', 'vi')}
                                                    currency={currencyCreator}
                                                    period={t('pricingPlanBannerPlan3')}
                                                    styles={{
                                                        container: styles.price,
                                                        currency: styles.priceCurrency,
                                                        value: styles.priceValue,
                                                        period: styles.pricePeriod,
                                                    }}
                                                />
                                            </>
                                        ),
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            </p>
                        ) : (
                            <p className={styles.piceBlock}>
                                {plansCreator[1]?.plans && (
                                    <Trans
                                        i18nKey="pricingPlanBanner3"
                                        components={{
                                            price: (
                                                <FormattedPrice
                                                    value={Math.floor(plansCreator[1].plans[0].perPeriodWithoutDiscountPrice)}
                                                    locale={locale?.replace('vn', 'vi')}
                                                    currency={currencyCreator}
                                                    period={t('pricingPlanBannerPlan3')}
                                                    styles={{
                                                        container: styles.price,
                                                        currency: styles.priceCurrency,
                                                        value: styles.priceValue,
                                                        period: styles.pricePeriod,
                                                    }}
                                                />
                                            ),
                                        }}
                                        ns="pages/homepage/2024/v1/index"
                                    />
                                )}
                            </p>
                        )}
                        <p className={styles.includeHeader}>{t('pricingPlanIncludeHeader3')}</p>
                        <div className={styles.features}>
                            <ul>
                                <Trans
                                    i18nKey="pricingPlanFeatures3"
                                    components={{
                                        li: <li />,
                                        strong: <strong />,
                                    }}
                                    ns="pages/homepage/2024/v1/index"
                                />
                            </ul>
                            <span className={styles.lastElement}>{t('pricingPlanFeaturesMore')}</span>
                        </div>
                        <div className={styles.banner}>
                            <Button
                                label={t('pricingPlanBannerButton3')}
                                size={'medium'}
                                color={'yellow'}
                                customLink={<CustomLink href={{ pathname: 'pricing', search: '?creators' }} />}
                            />
                        </div>
                    </div>
                </>
                {!isPromoActive && (
                    <div className={styles.planHeadDouble}>
                        <SVGMarketersIcon />
                        <span>{t('pricingMarketers')}</span>
                        <SVGMarketersIcon />
                    </div>
                )}
            </div>
        </section>
    );
};

export default Pricing;
