import React from 'react';

import AutomatorMaskAVIF from './assets/automator_mask@1x.avif';
import AutomatorMaskPNG from './assets/automator_mask@1x.png';
import AutomatorMaskAVIF2x from './assets/automator_mask@2x.avif';
import AutomatorMaskPNG2x from './assets/automator_mask@2x.png';
import CartMasterMaskAVIF from './assets/cartmaster_mask@1x.avif';
import CartMasterMaskPNG from './assets/cartmaster_mask@1x.png';
import CartMasterMaskAVIF2x from './assets/cartmaster_mask@2x.avif';
import CartMasterMaskPNG2x from './assets/cartmaster_mask@2x.png';
import CreatorMaskAVIF from './assets/creator_mask@1x.avif';
import CreatorMaskPNG from './assets/creator_mask@1x.png';
import CreatorMaskAVIF2x from './assets/creator_mask@2x.avif';
import CreatorMaskPNG2x from './assets/creator_mask@2x.png';
import InboxerMaskAVIF from './assets/inboxer_mask@1x.avif';
import InboxerMaskPNG from './assets/inboxer_mask@1x.png';
import InboxerMaskAVIF2x from './assets/inboxer_mask@2x.avif';
import InboxerMaskPNG2x from './assets/inboxer_mask@2x.png';
import MaximizerMaskAVIF from './assets/maximizer_mask@1x.avif';
import MaximizerMaskPNG from './assets/maximizer_mask@1x.png';
import MaximizerMaskAVIF2x from './assets/maximizer_mask@2x.avif';
import MaximizerMaskPNG2x from './assets/maximizer_mask@2x.png';

export const AutomatorMask = () => {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${AutomatorMaskAVIF.src} 1x, ${AutomatorMaskAVIF2x.src} 2x`}
            />
            <source srcSet={`${AutomatorMaskPNG.src} 1x, ${AutomatorMaskPNG2x.src} 2x`} />
            <img
                width={AutomatorMaskPNG.width}
                height={AutomatorMaskPNG.height}
                decoding="async"
                alt=""
                src={AutomatorMaskPNG.src}
            />
        </picture>
    );
};
export const CartMasterMask = () => {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${CartMasterMaskAVIF.src} 1x, ${CartMasterMaskAVIF2x.src} 2x`}
            />
            <source srcSet={`${CartMasterMaskPNG.src} 1x, ${CartMasterMaskPNG2x.src} 2x`} />
            <img
                width={CartMasterMaskPNG.width}
                height={CartMasterMaskPNG.height}
                decoding="async"
                alt=""
                src={CartMasterMaskPNG.src}
            />
        </picture>
    );
};
export const CreatorMask = () => {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${CreatorMaskAVIF.src} 1x, ${CreatorMaskAVIF2x.src} 2x`}
            />
            <source srcSet={`${CreatorMaskPNG.src} 1x, ${CreatorMaskPNG2x.src} 2x`} />
            <img
                width={CreatorMaskPNG.width}
                height={CreatorMaskPNG.height}
                decoding="async"
                alt=""
                src={CreatorMaskPNG.src}
            />
        </picture>
    );
};
export const InboxerMask = () => {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${InboxerMaskAVIF.src} 1x, ${InboxerMaskAVIF2x.src} 2x`}
            />
            <source srcSet={`${InboxerMaskPNG.src} 1x, ${InboxerMaskPNG2x.src} 2x`} />
            <img
                width={InboxerMaskPNG.width}
                height={InboxerMaskPNG.height}
                decoding="async"
                alt=""
                src={InboxerMaskPNG.src}
            />
        </picture>
    );
};
export const MaximizerMask = () => {
    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${MaximizerMaskAVIF.src} 1x, ${MaximizerMaskAVIF2x.src} 2x`}
            />
            <source srcSet={`${MaximizerMaskPNG.src} 1x, ${MaximizerMaskPNG2x.src} 2x`} />
            <img
                width={MaximizerMaskPNG.width}
                height={MaximizerMaskPNG.height}
                decoding="async"
                alt=""
                src={MaximizerMaskPNG.src}
            />
        </picture>
    );
};
