import React, { useContext, useState } from 'react';

import ScrollableTabs from './ScrollableTabs';
import Trans from '@components/Trans';
import dynamic from 'next/dynamic';
import styles from './Goals.module.css';
import { useInView } from 'react-intersection-observer';
import { useRouter } from 'next/router';
import useTranslation from '@hooks/useTranslation';

const AnimAutomaticallyEngageEn = dynamic(() => import('./animations/AutomaticallyEngage/lottieEn'), {
    ssr: false,
});
const AnimAutomaticallyEngagePl = dynamic(() => import('./animations/AutomaticallyEngage/lottiePl'), {
    ssr: false,
});
const AnimAutomaticallyEngageDe = dynamic(() => import('./animations/AutomaticallyEngage/lottieDe'), {
    ssr: false,
});
const AnimAutomaticallyEngageEs = dynamic(() => import('./animations/AutomaticallyEngage/lottieEs'), {
    ssr: false,
});
const AnimAutomaticallyEngagePt = dynamic(() => import('./animations/AutomaticallyEngage/lottiePt'), {
    ssr: false,
});
const AnimAutomaticallyEngageIt = dynamic(() => import('./animations/AutomaticallyEngage/lottieIt'), {
    ssr: false,
});
const AnimAutomaticallyEngageFr = dynamic(() => import('./animations/AutomaticallyEngage/lottieFr'), {
    ssr: false,
});
const AnimGrowYourListEn = dynamic(() => import('./animations/GrowYourList/lottieEn'), {
    ssr: false,
});
const AnimGrowYourListPl = dynamic(() => import('./animations/GrowYourList/lottiePl'), {
    ssr: false,
});
const AnimGrowYourListDe = dynamic(() => import('./animations/GrowYourList/lottieDe'), {
    ssr: false,
});
const AnimGrowYourListEs = dynamic(() => import('./animations/GrowYourList/lottieEs'), {
    ssr: false,
});
const AnimGrowYourListPt = dynamic(() => import('./animations/GrowYourList/lottiePt'), {
    ssr: false,
});
const AnimGrowYourListIt = dynamic(() => import('./animations/GrowYourList/lottieIt'), {
    ssr: false,
});
const AnimGrowYourListFr = dynamic(() => import('./animations/GrowYourList/lottieFr'), {
    ssr: false,
});
const AnimMaximizeRevenueEn = dynamic(() => import('./animations/MaximizeRevenue/lottieEn'), {
    ssr: false,
});
const AnimMaximizeRevenuePl = dynamic(() => import('./animations/MaximizeRevenue/lottiePl'), {
    ssr: false,
});
const AnimMaximizeRevenueDe = dynamic(() => import('./animations/MaximizeRevenue/lottieDe'), {
    ssr: false,
});
const AnimMaximizeRevenueEs = dynamic(() => import('./animations/MaximizeRevenue/lottieEs'), {
    ssr: false,
});
const AnimMaximizeRevenuePt = dynamic(() => import('./animations/MaximizeRevenue/lottiePt'), {
    ssr: false,
});
const AnimMaximizeRevenueIt = dynamic(() => import('./animations/MaximizeRevenue/lottieIt'), {
    ssr: false,
});
const AnimMaximizeRevenueFr = dynamic(() => import('./animations/MaximizeRevenue/lottieFr'), {
    ssr: false,
});
const AnimPromoteAndSellEn = dynamic(() => import('./animations/PromoteAndSell/lottieEn'), {
    ssr: false,
});
const AnimPromoteAndSellPl = dynamic(() => import('./animations/PromoteAndSell/lottiePl'), {
    ssr: false,
});
const AnimPromoteAndSellDe = dynamic(() => import('./animations/PromoteAndSell/lottieDe'), {
    ssr: false,
});
const AnimPromoteAndSellEs = dynamic(() => import('./animations/PromoteAndSell/lottieEs'), {
    ssr: false,
});
const AnimPromoteAndSellPt = dynamic(() => import('./animations/PromoteAndSell/lottiePt'), {
    ssr: false,
});
const AnimPromoteAndSellIt = dynamic(() => import('./animations/PromoteAndSell/lottieIt'), {
    ssr: false,
});
const AnimPromoteAndSellFr = dynamic(() => import('./animations/PromoteAndSell/lottieFr'), {
    ssr: false,
});

const inViewOptions = {
    threshold: 0.1,
    triggerOnce: true,
};

interface TabProps {
    loading?: 'eager' | 'lazy';
}

const Tab1 = ({ loading = 'eager' }: TabProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    const tabContext = useContext(ScrollableTabs.TabContext);
    const { ref: inViewRef, inView } = useInView(inViewOptions);
    const [wasEverActive, setWasEverActive] = useState(false);
    if (!wasEverActive && tabContext.active) {
        setWasEverActive(true);
    }
    return (
        <div className={[styles.tabContent, tabContext.active ? styles.tabContentActive : null].filter(Boolean).join(' ')}>
            <div
                className={styles.tabContentAnimation}
                ref={inViewRef}
                data-animation="1"
            >
                {(loading === 'eager' || inView) &&
                    (locale === 'pl' ? (
                        <AnimGrowYourListPl inView={inView && wasEverActive} />
                    ) : locale === 'de' ? (
                        <AnimGrowYourListDe inView={inView && wasEverActive} />
                    ) : locale === 'es' ? (
                        <AnimGrowYourListEs inView={inView && wasEverActive} />
                    ) : locale === 'fr' ? (
                        <AnimGrowYourListFr inView={inView && wasEverActive} />
                    ) : locale === 'it' ? (
                        <AnimGrowYourListIt inView={inView && wasEverActive} />
                    ) : locale === 'pt' ? (
                        <AnimGrowYourListPt inView={inView && wasEverActive} />
                    ) : (
                        <AnimGrowYourListEn inView={inView && wasEverActive} />
                    ))}
            </div>
            <div
                data-tab="1"
                className={styles.tabContentText}
                dangerouslySetInnerHTML={{ __html: t('goalsTab1Description') }}
            />
        </div>
    );
};
const Tab2 = ({ loading = 'eager' }: TabProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    const tabContext = useContext(ScrollableTabs.TabContext);
    const { ref: inViewRef, inView } = useInView(inViewOptions);
    const [wasEverActive, setWasEverActive] = useState(false);
    if (!wasEverActive && tabContext.active) {
        setWasEverActive(true);
    }
    return (
        <div className={[styles.tabContent, tabContext.active ? styles.tabContentActive : null].filter(Boolean).join(' ')}>
            <div
                className={styles.tabContentAnimation}
                data-animation="2"
                ref={inViewRef}
            >
                {(loading === 'eager' || inView) &&
                    (locale === 'pl' ? (
                        <AnimAutomaticallyEngagePl inView={inView && wasEverActive} />
                    ) : locale === 'de' ? (
                        <AnimAutomaticallyEngageDe inView={inView && wasEverActive} />
                    ) : locale === 'es' ? (
                        <AnimAutomaticallyEngageEs inView={inView && wasEverActive} />
                    ) : locale === 'fr' ? (
                        <AnimAutomaticallyEngageFr inView={inView && wasEverActive} />
                    ) : locale === 'it' ? (
                        <AnimAutomaticallyEngageIt inView={inView && wasEverActive} />
                    ) : locale === 'pt' ? (
                        <AnimAutomaticallyEngagePt inView={inView && wasEverActive} />
                    ) : (
                        <AnimAutomaticallyEngageEn inView={inView && wasEverActive} />
                    ))}
            </div>
            <div
                data-tab="2"
                className={styles.tabContentText}
                dangerouslySetInnerHTML={{ __html: t('goalsTab2Description') }}
            />
        </div>
    );
};
const Tab3 = ({ loading = 'eager' }: TabProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    const tabContext = useContext(ScrollableTabs.TabContext);
    const { ref: inViewRef, inView } = useInView(inViewOptions);
    const [wasEverActive, setWasEverActive] = useState(false);
    if (!wasEverActive && tabContext.active) {
        setWasEverActive(true);
    }
    return (
        <div className={[styles.tabContent, tabContext.active ? styles.tabContentActive : null].filter(Boolean).join(' ')}>
            <div
                className={styles.tabContentAnimation}
                ref={inViewRef}
                data-animation="3"
            >
                {(loading === 'eager' || inView) &&
                    (locale === 'pl' ? (
                        <AnimPromoteAndSellPl inView={inView && wasEverActive} />
                    ) : locale === 'de' ? (
                        <AnimPromoteAndSellDe inView={inView && wasEverActive} />
                    ) : locale === 'es' ? (
                        <AnimPromoteAndSellEs inView={inView && wasEverActive} />
                    ) : locale === 'fr' ? (
                        <AnimPromoteAndSellFr inView={inView && wasEverActive} />
                    ) : locale === 'it' ? (
                        <AnimPromoteAndSellIt inView={inView && wasEverActive} />
                    ) : locale === 'pt' ? (
                        <AnimPromoteAndSellPt inView={inView && wasEverActive} />
                    ) : (
                        <AnimPromoteAndSellEn inView={inView && wasEverActive} />
                    ))}
            </div>
            <div
                data-tab="3"
                className={styles.tabContentText}
                dangerouslySetInnerHTML={{ __html: t('goalsTab3Description') }}
            />
        </div>
    );
};
const Tab4 = ({ loading = 'eager' }: TabProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');
    const { locale } = useRouter();
    const tabContext = useContext(ScrollableTabs.TabContext);
    const { ref: inViewRef, inView } = useInView(inViewOptions);
    const [wasEverActive, setWasEverActive] = useState(false);
    if (!wasEverActive && tabContext.active) {
        setWasEverActive(true);
    }
    return (
        <div className={[styles.tabContent, tabContext.active ? styles.tabContentActive : null].filter(Boolean).join(' ')}>
            <div
                className={styles.tabContentAnimation}
                ref={inViewRef}
                data-animation="4"
            >
                {(loading === 'eager' || inView) &&
                    (locale === 'pl' ? (
                        <AnimMaximizeRevenuePl inView={inView && wasEverActive} />
                    ) : locale === 'de' ? (
                        <AnimMaximizeRevenueDe inView={inView && wasEverActive} />
                    ) : locale === 'es' ? (
                        <AnimMaximizeRevenueEs inView={inView && wasEverActive} />
                    ) : locale === 'fr' ? (
                        <AnimMaximizeRevenueFr inView={inView && wasEverActive} />
                    ) : locale === 'it' ? (
                        <AnimMaximizeRevenueIt inView={inView && wasEverActive} />
                    ) : locale === 'pt' ? (
                        <AnimMaximizeRevenuePt inView={inView && wasEverActive} />
                    ) : (
                        <AnimMaximizeRevenueEn inView={inView && wasEverActive} />
                    ))}
            </div>
            <div
                data-tab="4"
                className={styles.tabContentText}
                dangerouslySetInnerHTML={{ __html: t('goalsTab4Description') }}
            />
        </div>
    );
};

interface GoalsProps {
    onActiveTabChange: Function;
}

const Goals = ({ onActiveTabChange }: GoalsProps) => {
    const { t } = useTranslation('pages/homepage/2024/v1/index');

    return (
        <section className={styles.goals}>
            <p className={styles.preheader}>{t('goalsPreheader')}</p>
            <h2 className={styles.header}>
                <Trans
                    i18nKey="goalsHeader"
                    components={{
                        u: <span />,
                    }}
                    ns="pages/homepage/2024/v1/index"
                />
            </h2>
            <ScrollableTabs
                onActiveTabChange={onActiveTabChange}
                tabs={[
                    {
                        title: t('goalsTab1Title'),
                        content: <Tab1 loading={'lazy'} />,
                    },
                    {
                        title: t('goalsTab2Title'),
                        content: <Tab2 loading={'lazy'} />,
                    },
                    {
                        title: t('goalsTab3Title'),
                        content: <Tab3 loading={'lazy'} />,
                    },
                    {
                        title: t('goalsTab4Title'),
                        content: <Tab4 loading={'lazy'} />,
                    },
                ]}
            />
        </section>
    );
};

export default Goals;
