import React from 'react';

import Trans from '@components/Trans';

import useTranslation from '@hooks/useTranslation';

import styles from './Heros.module.css';
import img1AVIF1x from './assets/1@1x.avif';
import img1PNG1x from './assets/1@1x.png';
import img1AVIF2x from './assets/1@2x.avif';
import img1PNG2x from './assets/1@2x.png';
import img2AVIF1x from './assets/2@1x.avif';
import img2PNG1x from './assets/2@1x.png';
import img2AVIF2x from './assets/2@2x.avif';
import img2PNG2x from './assets/2@2x.png';
import img3AVIF1x from './assets/3@1x.avif';
import img3PNG1x from './assets/3@1x.png';
import img3AVIF2x from './assets/3@2x.avif';
import img3PNG2x from './assets/3@2x.png';
import img4AVIF1x from './assets/4@1x.avif';
import img4PNG1x from './assets/4@1x.png';
import img4AVIF2x from './assets/4@2x.avif';
import img4PNG2x from './assets/4@2x.png';
import img5AVIF1x from './assets/5@1x.avif';
import img5PNG1x from './assets/5@1x.png';
import img5AVIF2x from './assets/5@2x.avif';
import img5PNG2x from './assets/5@2x.png';

export default function Heros() {
    const ns = 'promo/black_friday_2024/exit_popup/index';
    const { t } = useTranslation(ns);
    return (
        <ul className={styles.list}>
            <li>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${img5AVIF1x.src} 1x, ${img5AVIF2x.src} 2x`}
                    />
                    <source srcSet={`${img5PNG1x.src} 1x, ${img5PNG2x.src} 2x`} />
                    <img
                        width={img5PNG1x.width}
                        height={img5PNG1x.height}
                        decoding="async"
                        loading="lazy"
                        src={img5PNG1x.src}
                        alt=""
                    />
                </picture>
                <span>
                    <Trans
                        i18nKey={'exitHero1'}
                        components={{
                            em: <em />,
                        }}
                        ns={ns}
                    />
                </span>
            </li>
            <li>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${img1AVIF1x.src} 1x, ${img1AVIF2x.src} 2x`}
                    />
                    <source srcSet={`${img1PNG1x.src} 1x, ${img1PNG2x.src} 2x`} />
                    <img
                        width={img1PNG1x.width}
                        height={img1PNG1x.height}
                        decoding="async"
                        loading="lazy"
                        src={img1PNG1x.src}
                        alt=""
                    />
                </picture>
                <span>
                    <Trans
                        i18nKey={'exitHero2'}
                        components={{
                            em: <em />,
                        }}
                        ns={ns}
                    />
                </span>
            </li>
            <li>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${img2AVIF1x.src} 1x, ${img2AVIF2x.src} 2x`}
                    />
                    <source srcSet={`${img2PNG1x.src} 1x, ${img2PNG2x.src} 2x`} />
                    <img
                        width={img2PNG1x.width}
                        height={img2PNG1x.height}
                        decoding="async"
                        loading="lazy"
                        src={img2PNG1x.src}
                        alt=""
                    />
                </picture>
                <span>
                    <Trans
                        i18nKey={'exitHero3'}
                        components={{
                            em: <em />,
                        }}
                        ns={ns}
                    />
                </span>
            </li>
            <li>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${img3AVIF1x.src} 1x, ${img3AVIF2x.src} 2x`}
                    />
                    <source srcSet={`${img3PNG1x.src} 1x, ${img3PNG2x.src} 2x`} />
                    <img
                        width={img3PNG1x.width}
                        height={img3PNG1x.height}
                        decoding="async"
                        loading="lazy"
                        src={img3PNG1x.src}
                        alt=""
                    />
                </picture>
                <span>
                    <Trans
                        i18nKey={'exitHero4'}
                        components={{
                            em: <em />,
                        }}
                        ns={ns}
                    />
                </span>
            </li>
            <li>
                <picture>
                    <source
                        type="image/avif"
                        srcSet={`${img4AVIF1x.src} 1x, ${img4AVIF2x.src} 2x`}
                    />
                    <source srcSet={`${img4PNG1x.src} 1x, ${img4PNG2x.src} 2x`} />
                    <img
                        width={img4PNG1x.width}
                        height={img4PNG1x.height}
                        decoding="async"
                        loading="lazy"
                        src={img4PNG1x.src}
                        alt=""
                    />
                </picture>
                <span>
                    <Trans
                        i18nKey={'exitHero5'}
                        components={{
                            em: <em />,
                        }}
                        ns={ns}
                    />
                </span>
            </li>
        </ul>
    );
}
